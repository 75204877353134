import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case/index.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('@/views/phone/home/index.vue'),
  },
  {
    path: '/slove',
    name: 'slove',
    component: () => import('@/views/slove/index.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});


 const originalPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

 }

export default router;
